var name = 'home';
page.names.push(name);

page[name] = class PageClassName extends page.PageClass{





    // init

    init(ajaxBool = false){

        super.init();



        // elements

        this.content = document.querySelector(".home__content");
        this.image = document.querySelector("#home__image");
        this.bg = document.querySelector(".home__image-bg");
        this.address = document.querySelector(".page__address");
        this.header = document.querySelector(".home__header");
        this.bookButton = document.querySelector(".home__bookButton");
        this.bookButtonMobile = document.querySelector(".home__bookButton_mobile");
        this.bookingButtons = document.querySelectorAll(".home__booking-button");
        this.preloader = document.querySelector(".home__screen .v-preloader__outer");



        // preloader

        if(this.preloader !== null){
            if(ajaxBool){
                this.preloader.classList.add("display_none_important");
            }
        }



        // second h2

        this.secondH2 = new v.TextAnimate({
            console: page.console,
            selector: '.home__second h2'
        });
        this.secondH2 = this.secondH2.init();



        // booking buttons

        this.bookingOpened = false;

        for(let i = 0; i < this.bookingButtons.length; i++){
            this.bookingButtons[i].addEventListener("click", function(button, e){

                if(!v.mobile()){

                    e.preventDefault();

                    if(!this.bookingOpened){

                        let bb = button.querySelectorAll("b");
                        for(let i = 0; i < bb.length; i++){
                            bb[i].innerHTML = button.getAttribute("data-text-close");
                        }

                        page.default.classList.add("contracted");
                        this.bookingOpened = true;

                    }
                    else{

                        let bb = button.querySelectorAll("b");
                        for(let i = 0; i < bb.length; i++){
                            bb[i].innerHTML = button.getAttribute("data-text-open");
                        }
                    
                        page.default.classList.remove("contracted");
                        this.bookingOpened = false;

                    }

                }

            }.bind(this, this.bookingButtons[i]));
        }



        /* Booking Module */

        let bookingAdults = document.querySelector(".home__booking input[name='adults']"),
            bookingAdultsTemp = document.querySelector(".home__booking input[name='adults_temp']"),
            bookingAdultsValue = document.querySelector(".home__booking-guests .value"),
            bookingButton = document.querySelector(".home__booking-submit .a-button"),
            bookingSubmit = document.querySelector(".home__booking-submit .a-submit");


        // adults

        let bookingSetAdults = function(){
            let value = bookingAdultsTemp.value;
            value = parseInt(value);
            let text = value;
            if(text < 10){
                text = '0' + text;
            }
            bookingAdultsTemp.value = text;
            bookingAdults.value = value;
        };

        bookingSetAdults();
        bookingAdultsTemp.addEventListener("blur", bookingSetAdults.bind(this));


        // submit

        bookingButton.addEventListener("click", function(e){

            e.preventDefault();

            if(bookingAdultsValue !== null){
                bookingAdultsValue.innerHTML = `<span>${bookingAdultsTemp.value}</span>`;
            }

            $(bookingSubmit).click();

        }.bind(this));



        // scroll

        this.scrollInitialized = false;



    }





    // show

    show(ajaxBool = false){

        super.show();



        if(this.image !== null){
            this.image.classList.add("show");
        }



        let timeout = 1250;
        if(v.mobile()){
            timeout = 25;
        }



        setTimeout(() => {

            this.address.classList.add("show");
            this.header.classList.add("show");
            this.bookButton.classList.add("show");
            this.bookButtonMobile.classList.add("show");

            page.logo.classList.add("show");
            page.menuButton.classList.add("show");

        }, timeout);

        setTimeout(function(ajaxBool){

            if(!v.mobile()){
                $(".home #v-scrollbar").smoothWheel();
                this.scrollInitialized = true;
            }

            if(v.mobile()){
                window.denyScroll = true;
            }
        
            this.addResizeEvent(function(){
                if(v.mobile()){
                    window.denyScroll = true;
                }
                else{
                    window.denyScroll = false;
                    if(!this.scrollInitialized){
                        $(".home #v-scrollbar").smoothWheel();
                    }
                }
            }.bind(this));

            page.default.classList.add("show");

        }.bind(this, ajaxBool), timeout + 250);



        // parallax first

        setTimeout(() => {

            this.imageParallax = new v.HorizontalParallax({
                console: page.console,
                scrollSelector: page.scrollSelector,
                selector: '.home__image-scale',
                k: -.3,
                do: function(p, e){

                    // parallax image
                    
                    p = p / .7;
                    if(p > 1){
                        p = 1;
                    }

                    e.style.transform = `scale(${ (1-p) * .25 + 1 })`;
                    this.bg.style.transform = `translateX(${p * (.25 * e.clientWidth)}px)`;
                    e.style.transition = `0s`;

                    // parallax content

                    p = p / .3;

                    if(p >= 1){
                        this.content.style.display = 'none';
                    }
                    else{
                        this.content.style.display = '';
                        this.content.style.opacity = 1 - p;
                    }

                }.bind(this)
            });
            this.imageParallax = this.imageParallax.init();
        
            // resize parallax
        
            function changeParallax(){
                if(this.imageParallax){
                    this.imageParallax.changeProp({
                        scrollSelector: page.scrollSelector
                    });
                }
            }
        
            this.resizeEvents.push(changeParallax.bind(this));

        }, timeout + 250);



        // view second h2

        this.secondH2View = new v.HorizontalView({
            console: page.console,
            scrollSelector: page.scrollSelector,
            selector: '.home__second h2',
            percent: .85,
            load: false
        });
        this.secondH2View = this.secondH2View.init();
        window.secondH2View = this.secondH2View;
    
        function changeView(){
            setTimeout(function(){
                if(this.secondH2View){
                    this.secondH2View.changeProp({
                        scrollSelector: page.scrollSelector
                    });
                }
            }.bind(this), 500);
        }
        
        this.resizeEvents.push(changeView.bind(this));

        if(this.secondH2View){
            this.secondH2View.add({
                target: 'in',
                do: function(){

                    if(this.secondH2){
                        this.secondH2.animate({
                            types: ['stringword'],
                            duration: 1000,
                            shift: 0,
                            shiftString: .2,
                            bezier: page.bezier,
                            word: [{
                                property: 'transform',
                                scope: [0, 1],
                                value: (p) => { return `translate(${(1 - p) * 50}px, 0)`; }
                            },{
                                property: 'opacity',
                                scope: [0, 1],
                                value: (p) => { return `${p}`; }
                            }]
                            //bezier: page.bezier,
                        });
                    }

                }.bind(this)
            })
        }



        // view horizontal

        this.horizontalView = new v.HorizontalView({
            console: page.console,
            scrollSelector: page.scrollSelector,
            selector: '.home__view',
            percent: .85,
            load: false
        });
        this.horizontalView = this.horizontalView.init();
    
        function changeHorizontalView(){
            if(this.horizontalView){
                this.horizontalView.changeProp({
                    scrollSelector: page.scrollSelector
                });
            }
        }
        
        this.resizeEvents.push(changeHorizontalView.bind(this));



        return true;

    }





    // hide

    hide(ajaxBool = false){

        super.hide();

    }





    // destroy

    destroy(ajaxBool = false){

        super.destroy();

    }





};

page[name] = new page[name];
page[name] = page[name].create();